import {ChangeDetectorRef, Injectable, OnInit} from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import {BehaviorSubject, Subject} from 'rxjs';
import {language, Module} from '../utils/systemEnums';
import {LanguageService} from './language.service';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import icDollar from '@iconify/icons-fa-solid/dollar-sign';
import IcDonut_large from '@iconify/icons-ic/twotone-donut-large';
import IcGroup from '@iconify/icons-ic/twotone-group';
import IcChallenge from '@iconify/icons-ic/twotone-change-history';
import IcNotification from '@iconify/icons-ic/twotone-notifications-active';
import {TranslateService} from '@ngx-translate/core';
import {AuthServiceLocal} from './auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NavigationService{
  items;
  items$;
  itemsArray = [];
  englishMenu: NavigationItem[];
  norwayMenu: NavigationItem[];
  lang = language;
  public sidebarData: any;
  isAdminLogin: boolean;

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  pageTitleSubject = new BehaviorSubject<any>({});
  pageTitle$ = this.pageTitleSubject.asObservable();

  constructor(private langService: LanguageService, private translate: TranslateService,
              private auth: AuthServiceLocal, private snackbar: MatSnackBar) {
    // this.getDashboardData();
    this.isAdminLogin = this.auth.isAdminLogin();
    console.log(this.isAdminLogin);
    this.sidebarData = this.auth.getSidebarData();
    this.setMenu();
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  public setMenu() {
    this.englishMenu = [
      {
        type: 'link',
        label: 'Company Actions',
        // route: this.isAdminLogin ? '/challenges/company-challenges' : '/challenges/library',
        route: 'library',
        // route: '/challenges',
        svgIcon: 'svgChallenge',
        isShowForUser: 1,
        moduleID: Module.Challenges,
      },
      {
        type: 'link',
        label: 'AI Manager',
        route: '/ai-manager',
        svgIcon: 'svgManager',
        isShowForUser: 1,
        moduleID: Module.managerAi,
      },
      {
        type: 'link',
        label: 'My Company',
        route: '/my-company/organisation-department',
        svgIcon: 'svgDepartment',
        isShowForUser: 1,
        moduleID: Module.department,
      },
      {
        type: 'link',
        label: 'Insight ',
        route: '/dashboard',
        svgIcon: 'svgDashboard',
        isShowForUser: 1,
        moduleID: Module.Dashboard,
      },
     
      // {
      //   type: 'link',
      //   label: 'Launch',
      //   // route: this.isAdminLogin ? '/challenges/company-challenges' : '/challenges/library',
      //   route: '/launch',
      //   // route: '/challenges',
      //   svgIcon: 'svgLaunch',
      //   isShowForUser: 1,
      //   moduleID: Module.Challenges,
      // },
      {
        type: 'link',
        label: 'Monthly Themes',
        route: '/monthly-themes/themes',
        svgIcon: 'svgTheme',
        isShowForUser: 0,
        moduleID: Module.monthlyThemes,
      },
      
      // {
      //   type: 'link',
      //   label: 'Teams',
      //   route: '/teams',
      //   svgIcon: 'svgTeam',
      //   isShowForUser: 1,
      //   moduleID: Module.Teams,
      //   badge: {
      //     value: this.sidebarData ? this.sidebarData.totalTeam : 0,
      //     bgClass: 'bg-primary',
      //     textClass: 'text-deep-purple-contrast',
      //   },
      // },
      
     
      // {
      //   type: 'link',
      //   label: 'Employees',
      //   route: '/organisation-employees',
      //   svgIcon: 'svgEmployee',
      //   isShowForUser: 1,
      //   moduleID: Module.Employees,
      // },
      {
        type: 'link',
        label: 'Leadership Program',
        route: '/coaching/create-program',
        svgIcon: 'svgCoaching',
        isShowForUser: 0,
        moduleID: Module.Coaching,
      },  
      {
        type: 'link',
        label: 'Reward',
        route: '/reward/reward-amount',
        svgIcon: 'svgReward',
        isShowForUser: 0,
        moduleID: Module.Rewards,
      },
      
    
      {
        type: 'link',
        label: 'Learning',
        route: '/wellness-categories',
        svgIcon: 'svgWellness',
        isShowForUser: 0,
        moduleID: Module.WellnessCategories,
      },
      {
        type: 'link',
        label: 'Praise A Colleague',
        route: '/praise-colleague',
        svgIcon: 'svgColleague',
        isShowForUser: 0,
        moduleID: Module.Recognitionfeature,
      },
      // {
      //   type: 'link',
      //   label: 'Roles',
      //   route: '/roles',
      //   svgIcon: 'svgRole',
      //   isShowForUser: 0,
      //   moduleID: Module.Roles,
      // },
      {
        type: 'link',
        label: 'Send Reminders',
        route: '/send-notification',
        svgIcon: 'svgSendNotification',
        isShowForUser: 0,
        moduleID: Module.SendNotifications,
      },
      // {
      //   type: 'link',
      //   label: 'Slack Integration',
      //   route: '/slack-integration',
      //   svgIcon: 'svgSlackIcon',
      //   isShowForUser: 1,
      //   moduleID: Module.SlackIntegration,
      // },
      {
        type: 'link',
        label: 'Events',
        route: '/events',
        svgIcon: 'svgEvent',
        isShowForUser: 0,
        moduleID: Module.Events,
      },
    ];

    this.norwayMenu = [
      {
        type: 'link',
        label: 'AI Manager',
        route: '/ai-manager',
        svgIcon: 'svgManager',
        isShowForUser: 1,
        moduleID: Module.managerAi,
      },
      {
        type: 'link',
        label: 'Insight ',
        route: '/dashbord',
        svgIcon: 'svgDashboard',
        isShowForUser: 1,
        moduleID: Module.Dashboard,
      },

      {
        type: 'link',
        label: 'Strategic Actions',
        route: this.isAdminLogin ? '/challenges/company-challenges' : '/challenges/library',
        svgIcon: 'svgChallenge',
        isShowForUser: 1,
        moduleID: Module.Challenges,
      },
      // {
      //   type: 'link',
      //   label: 'Launch',
      //   // route: this.isAdminLogin ? '/challenges/company-challenges' : '/challenges/library',
      //   route: '/launch',
      //   // route: '/challenges',
      //   svgIcon: 'svgLaunch',
      //   isShowForUser: 1,
      //   moduleID: Module.Challenges,
      // },

      {
        type: 'link',
        label: 'Grupper',
        route: '/teams',
        svgIcon: 'svgTeam',
        isShowForUser: 1,
        moduleID: Module.Teams,
        badge: {
          value: this.sidebarData ? this.sidebarData.totalTeam : 0,
          bgClass: 'bg-primary',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Ansatte',
        route: '/organisation-employees',
        svgIcon: 'svgEmployee',
        isShowForUser: 1,
        moduleID: Module.Employees,
      },
      {
        type: 'link',
        label: 'Leadership Program',
        route: '/coaching',
        svgIcon: 'svgCoaching',
        isShowForUser: 1,
        moduleID: Module.Coaching,
      }, 
      {
        type: 'link',
        label: 'Belønningsbeløp',
        route: '/reward-amount',
        svgIcon: 'svgReward',
        isShowForUser: 0,
        moduleID: Module.Rewards,
      },
      {
        type: 'link',
        label: 'velvære',
        route: '/wellness-categories',
        svgIcon: 'svgWellness',
        isShowForUser: 0,
        moduleID: Module.WellnessCategories,
      },
      {
        type: 'link',
        label: 'Belønningstype',
        route: '/reward-type',
        svgIcon: 'svgNgo',
        isShowForUser: 0,
        moduleID: Module.Ngo,
      },
      {
        type: 'link',
        label: 'Ros kollega',
        route: '/praise-colleague',
        svgIcon: 'svgColleague',
        isShowForUser: 1,
        moduleID: Module.Recognitionfeature,
      },
      {
        type: 'link',
        label: 'Roller',
        route: '/roles',
        svgIcon: 'svgRole',
        isShowForUser: 0,
        moduleID: Module.Roles,
      },
      {
        type: 'link',
        label: 'Send Påminnelser',
        route: '/send-notification',
        svgIcon: 'svgSendNotification',
        isShowForUser: 0,
        moduleID: Module.SendNotifications,
      },
      // {
    //   type: 'link',
    //   label: 'Slakk integrering',
    //   route: '/slack-integration',
    //   svgIcon: 'svgSlackIcon',
    //   isShowForUser: 1,
    //   moduleID: Module.SlackIntegration,
    // },
    {
      type: 'link',
      label: 'Events',
      route: '/events',
      svgIcon: 'svgEvent',
      isShowForUser: 1,
      moduleID: Module.Events,
    },
    ];

    this.items = new BehaviorSubject<NavigationItem[]>(this.englishMenu);
    this.items$ = this.items.asObservable();

    const lang = this.langService.getLanguage();
    this.publishNavigationChange(lang);
  }

  public publishNavigationChange(type) {
    switch (type) {
      case this.lang.English:
        this.items.next(this.englishMenu);
        this.filterMenuItemsForUser(this.items._value);
        break;
      case this.lang.Norway:
        this.items.next(this.norwayMenu);
        this.filterMenuItemsForUser(this.items._value);
        break;
      default:
        this.items.next(this.englishMenu);
        this.filterMenuItemsForUser(this.items._value);
    }
  }

  filterMenuItemsForUser(items) {
    const user = this.auth.getUser();
    // console.log('items in navigation service',items)
    if (user) {
      const isAdminLogin = this.auth.isAdminLogin();
      if (isAdminLogin) {
        items.map((item: any) => {
          if(item.children){
            item.children = item?.children?.filter(child => child.isShowForAdmin === 1)
          }
        });
        this.itemsArray = items;
      } else {
        const mainItems = items.filter(x => x.isShowForUser === 1);
        mainItems.map((item: any) => {
          if(item.children){
            item.children = item?.children?.filter(child => child.isShowForUser === 1)
          }
        });
        this.itemsArray = mainItems;
      }
    } else {
      this.itemsArray = items;
    }
  }

}
