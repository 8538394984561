import {Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalStoreService} from './local-store.service';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    private errorData: { errorDesc: string; errorTitle: string };
    localTimeZone: any;

    constructor(public http: HttpClient, private router: Router,
                private store: LocalStoreService, private zone: NgZone,private snackbar: MatSnackBar) {

                    var timeZoneOffset = new Date().getTimezoneOffset() / 60;
                    this.localTimeZone = timeZoneOffset * 60 * 60 *1000
                    // this.localTimeZone = this.localTimeZone.toString()
                    this.localTimeZone = this.convertValues(this.localTimeZone).toString()
                    console.log('localTimeZone',this.localTimeZone)
                }

    logout() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }
            

                convertValues(number) {
                    if (number < 0) {
                      return Math.abs(number);
                    } else {
                      return -number;
                    }
                  }
                  
    getToken() {
        return this.store.getItem('token');
    }
    getLanguage() {
        return this.store.getItem('language');
    }

    getOrganisationId() {
        const loggedUser = this.store.getItem('loggedUser');
        return loggedUser.organisationId?.toString();
    }

    public getData<T>(url: string) {
        return this.http.get(url).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeader<T>(url: string) {
       
        // console.log(this.localTimeZone)
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                Authorization: this.getToken(),
                platform:  'Web',
                organisationId: this.getOrganisationId(),
                lanuguage: this.getLanguage(),
                timeZoneOffset:this.localTimeZone
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public getDataWithoutHeader<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',               
                'platform': 'Web',
                lanuguage: this.getLanguage(),
                timeZoneOffset:this.localTimeZone   
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
       
    }


   
    public postDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
              //  'content-type':'multipart/form-data',
                Authorization: this.getToken(),
                platform:  'Web',
                organisationId: this.getOrganisationId(),
                time: new Date().getTime().toString(),
                lanuguage: this.getLanguage(),
                timeZoneOffset:this.localTimeZone
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postDataWithHeaderFormData<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.getToken(),
                organisationId: this.getOrganisationId(),
                timeZoneOffset:this.localTimeZone
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public putDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.getToken(),
                platform:  'Web',
                organisationId: this.getOrganisationId(),
                time: new Date().getTime().toString(),
                lanuguage: this.getLanguage(),
                timeZoneOffset:this.localTimeZone,
            })
        };
        return this.http.put(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public deleteDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.getToken(),
                platform:  'Web',
                organisationId: this.getOrganisationId(),
                time: new Date().getTime().toString(),
                lanuguage: this.getLanguage(),
                timeZoneOffset:this.localTimeZone,
            }),
            body : toAdd,
        };
        return this.http.delete(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postDataWithoutHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                lanuguage: 'en',
                timeZoneOffset:this.localTimeZone,
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postData<T>(url: string, toAdd: any) {
        return this.http.post(url, toAdd).pipe(
            catchError(this.handleError)
        );
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            if (error.status === 401) {
                console.log("logout user",localStorage.clear())
                localStorage.clear();  
                this.snackbar.open("user is deleted")             
                sessionStorage.clear();
                this.router.navigate(['/'])
                
            }
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        this.errorData = {
            errorTitle: 'Oops! Request for document failed',
            errorDesc: 'Something bad happened. Please try again later.'
        };
        return throwError(this.errorData);
    }
    
}
