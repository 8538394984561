import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardInfoComponent } from '../dashboard-info/dashboard-info.component';
import { AuthServiceLocal } from '../services/auth.service';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import ApexCharts from 'apexcharts';

@Component({
  selector: 'vex-donation-data',
  templateUrl: './donation-data.component.html',
  styleUrls: ['./donation-data.component.scss']
})
export class DonationDataComponent implements OnInit {

  currencySymbol: any;
  donationChart;
  donationChartOptions = {
    series: [
      {
        name: 'Donation Amount',
        data: []
      }
    ],
    annotations: {
      points: [
        {
          x: 'Months',
        }
      ]
    },
    chart: {
      height: 300,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    grid: {
      row: {
        colors: ['#fff', '#f2f2f2']
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: [],
      tickPlacement: 'on'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100]
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  donationGraphData;
  totalDonation: any;
  donationChartData: any;
  totalDonationCount: any;
  d = new Date();
  currentYear = this.d.getFullYear()
  yeardDashboardFilter: any;
  yearFilterForDashboard = {
    'year': this.currentYear
  }
  yearFilter = [
    { name: '2021', value: 2021 },
    { name: '2022', value: 2022 },
    // { name: '2023', value: 2023 },
    // { name: '1 Year', value: '1Y' },
  ];

  icFilterList =icFilterList
  isSelectedForDonation = this.currentYear;
  donationGoalCh: boolean = false;
  yearData: any;
  constructor(
    private dialog: MatDialog,
    private auth :AuthServiceLocal,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getDonationChartData(this.yearFilterForDashboard.year);
    this.getYearFilterData()
  }

getDonationChartData(year) {
      const payload = {
        year: year
      }
      this.donationGraphData = [];
      this.donationChartOptions.series[0].data = [];
      this.donationChartOptions.xaxis.categories = [];
      this.auth.getDonationChartData(payload).subscribe((response: any) => {
        if (response.status === 200) {
          this.donationChartData = response.data;
          this.totalDonationCount = this.donationChartData.reduce((sum: any, current: any) => { return sum + current.dataCount }, 0).toString();
        }
        if (response.status == 200) {
          this.donationGraphData = response.data;
          this.currencySymbol = response.currencyData
          this.donationGraphData.forEach(item => {
            console.log("item for map ===>",  this.donationChartOptions.xaxis.categories)
            this.donationChartOptions.series[0].data.push(item.dataCount);
            this.donationChartOptions.xaxis.categories.push(item.xAxisLabel);
          });
          this.totalDonation = this.donationChartOptions.series[0].data.reduce((a, b) => a + b, 0)
          this.totalDonation = Math.round(this.totalDonation)
          this.totalDonation = this.totalDonation.toString();
          this.totalDonation = this.totalDonation.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          this.donationChart = new ApexCharts(document.querySelector('#donationChart'), this.donationChartOptions);
          this.donationChart.render();
        } else {
          this.snackBar.open(response.message);
        }
      })
    }
  
   changeActFilter(event, type, month?) {
    this.yeardDashboardFilter = event;
    if (type == 'donation') {
      this.donationChart.destroy();
      this.donationChart =''
      this.getDonationChartData(this.yeardDashboardFilter);
    }
  }

  openFilter(type) {
    if (type == 'donation') {
      if (this.donationGoalCh) {
        this.donationGoalCh = false;
      } else {
        this.donationGoalCh = true;
      }
    }
  }
  openModal(val, text) {
    console.log("hello were", text)
    this.dialog.open(DashboardInfoComponent, {
      height: "150px",
      panelClass: 'dashboard-dailog',
      data: {
        text: val,
        title: text
      }
    })
  }
  getYearFilterData(){ 
    const payload ={
      isYear : 1,
      isMonth :0,
    }
     
      this.auth.getFilteredData(payload).subscribe((response: any) => {
        if (response.status === 200) {
         console.log("year data ",response.data)
         this.yearData = response.data.years
        
        }
      });
    }
}
