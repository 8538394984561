<div class="d-flex flex info-title">
    <h1 mat-dialog-title>{{this.data.text | titlecase}}</h1>
    <mat-icon class="close-btn cursor-pointer" mat-dialog-close [icIcon]="icClose"></mat-icon>
</div>

<div mat-dialog-content class="info-details">
    {{this.data.title}}
</div>


