import { Injectable } from '@angular/core';
import {language} from '../utils/systemEnums';
import {LocalStoreService} from './local-store.service';
import usFlag from '@iconify/icons-emojione/flag-for-flag-united-states';
import norwayFlag from '@iconify/icons-emojione/flag-for-flag-norway';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public language = language;
  public selectedLang;

  constructor(private store: LocalStoreService, private translate: TranslateService) {
    this.selectedLang = this.getLanguage();

    if (!this.selectedLang) {
      this.selectedLang  = language.English;
    }
    this.setLanguage(this.selectedLang);
  }

  public setLanguage(lng) {
    this.translate.use(lng);
    // await this.translate.use(lng).toPromise<void>();
    return this.store.setItem('language', lng);
  }

  public getLanguage() {
    return this.store.getItem('language');
  }
}
