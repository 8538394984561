<div class="detail-dailog" >
    <mat-dialog-content>
        <div class="w-full mb-5">

            <div *ngIf="this.praiseHistroy.emojiType ==1 "><img class="emoji_icon" src="../../assets/img/praise-colleague/chocolate.png"></div>
            <div *ngIf="this.praiseHistroy.emojiType == 2"><img class="emoji_icon" src="../../assets/img/praise-colleague/hand.png"></div>
            <div *ngIf="this.praiseHistroy.emojiType == 3"><img class="emoji_icon" src="../../assets/img/praise-colleague/party.png"></div>
            <div *ngIf="this.praiseHistroy.emojiType == 5"><img class="emoji_icon" src="../../assets/img/praise-colleague/star.png"></div>
            <!-- <h2 class="emoji-title">Team Abdi</h2> -->
            <div class="pt-4 detail-chips " >
                <mat-chip-list aria-label="selection" class=" pt-2" >
                    <mat-chip *ngFor="let item of this.praiseHistroy.sendTo">{{item}}</mat-chip>
                    
                </mat-chip-list>
            </div>
            <div *ngIf="this.praiseHistroy.message.length > 1" class="praise-msg"><p class="p-3">{{this.praiseHistroy.message}}</p></div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button class="emoji text-contrast-white mt-2 mb-2" mat-dialog-close tabindex="-1">Close</button>
    </mat-dialog-actions>
</div>