import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceLocal } from '../services/auth.service';
import { LocalStoreService } from '../services/local-store.service';

@Component({
  selector: 'vex-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  isTutorialChecked = 0;
  loggedUser;
  @Input() data: any;

  @ViewChild('sidenav') elRef: ElementRef;
  constructor(private router: Router, private auth: AuthServiceLocal,
    private store: LocalStoreService) {
    this.loggedUser = this.auth.getUser();
    this.isTutorialChecked = this.loggedUser.isTutorialChecked;

  }




  ngOnInit(): void {
    const isAdminLogin = this.auth.isAdminLogin();

    if (!isAdminLogin) {
      if (this.data.url === '/slack-intergration') {
        this.data.buttonName = 'Finish';
      }
    }
  }


  /**
   * function used when click on next button in tutorial
   * @param event = event
   */
  redirect(event) {
    const isAdminLogin = this.auth.isAdminLogin();
   
    
    if (isAdminLogin) {
      console.log("helloo in else condition ", this.data.url)
      console.log(isAdminLogin);
      if (this.data.url === '/') {
        event.stopPropagation();
        this.updateTutorial();
      } else {
        console.log(this.data.url);
        if (this.data.url == '/challenges') {
          this.router.navigate([this.data.url + '/company-challenges']);
        } else {
          this.router.navigate([this.data.url]);
        }
      }
    } else {
      if (this.data.url === '/') {
        this.data.buttonName = 'Finish';
        event.stopPropagation();
        this.updateTutorial();
        this.router.navigate(['/']);
      } else if (this.data.url == '/challenges') {
        this.router.navigate([this.data.url + '/library']);
      } else {
        this.router.navigate([this.data.url]);
      }
    }

  }


  /**
   * On form submit
   * */
  updateTutorial() {
    this.auth.updateTutorial().subscribe((response: any) => {
      if (response.status === 200) {
        this.isTutorialChecked = response.data.isTutorialChecked;
        this.loggedUser.isTutorialChecked = this.isTutorialChecked;
        this.store.setItem('loggedUser', this.loggedUser);
      }
    });
  }

}