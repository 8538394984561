<mat-dialog-content>
    <div class="w-full mb-5">
        <h3 class="text-center" *ngIf="isStop"> {{'message.StopConfirmation' | translate}} </h3>
        <h3 class="text-center" *ngIf="!isStop"> {{'message.deleteConfirmation' | translate}} </h3>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close tabindex="-1" (click)="onConfirmClick()">{{'buttons.yes' | translate}}</button>
    <button mat-raised-button class="bg-gray text-contrast-black" mat-dialog-close tabindex="-1">{{'buttons.no' | translate}}</button>
</mat-dialog-actions>
