<div class="search-box-dailog">
     <div class="flex justify-between ">
          <h3 class=" fw-bold pb-3">Please Select Team or Colleague </h3>
          <button mat-button type="button" class="btn-close" (click)="close()">
              <mat-icon [icIcon]="icClose" size="28px" ></mat-icon>
          </button>

      </div>
      <!-- <button mat-raised-button class="btn btn-primary">Continue</button> -->
      <div>
          <div class="bg-white rounded-full border px-4 mb-3 flex" fxFlex="" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
              fxLayoutAlign="start center">
              <img src="../../assets/img/icons/search_icon.png" class="search_icon">
              <input [(ngModel)]="searchText"  autocomplete="off" class="pt-3 py-3 border-0 outline-none w-full bg-white searchInput"
                  placeholder="@ Find a team or a colleague" type="search">

          </div>
          <span fxFlex></span>
      </div>

      <div>
          <div class="mt-2 search-results ">
              <!-- <mat-checkbox class="pt-2" *ngFor="let element of colleague | filter: searchText " (change)="checkCheckBoxvalue(element); " [value]="element._id" >
                  <h4 class="pl-5">{{element.name}}</h4>
              </mat-checkbox> -->
          <section class="example-section">
              <span class="example-list-section">
                <ul>
                  <li *ngFor="let element of colleague | filter: searchText ">
                    <mat-checkbox [(ngModel)]="element.checked"  (ngModelChange)="checkCheckBoxvalue(element)">
                      {{element.name}}
                    </mat-checkbox>
                  </li>
                </ul>
              </span>
            </section>
          </div>
      </div>

      <div class="m-auto"><button mat-raised-button class="add-btn" (click)="addMember()">ADD</button></div>

</div>