<div class="card new-card ">
    <!-- <div class="px-6 py-4" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="m-0 title new-card-title" fxFlex="auto">{{'Physical Well-Being' | uppercase}}</h2>
        <img class="info" src="../../../assets/img/information_icon.png"
            (click)="openModal('Physical Well-Being','Number of steps and physical activities registered by the employees in your company.')">
    </div> -->

    <div class="px-6 py-4 border-b" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="m-0 title new-card-title" fxFlex="auto">{{'Physical Well-Being' | uppercase}}</h2>
        <span><span class="font-weight-bold text-primary"> {{totalCmpanyActivity}} </span> Activities
        </span>
        <mat-icon class="cursor-pointer" [icIcon]="icFilterList" (click)="openFilter('physical')"
            matTooltip="Filter" class="ml-3"></mat-icon>
        <img class="ml-2 cursor-pointer info" src="../../../assets/img/information_icon.png"
            (click)="openModal('Physical Well-Being','Number of steps and physical activities registered by the employees in your company.')">
    </div>

    <div class="filter-section" *ngIf="physicalWellBeingch" [class.opened]="physicalWellBeingch">
        <mat-form-field fxFlex="100">
            <mat-label> Select Year </mat-label>
            <mat-select [(value)]="isSelectedForPhysical"
                (selectionChange)="changeActFilter($event.value,'physical')">
                <mat-option *ngFor="let option of  this.yearData | keyvalue" [value]="option.value">
                    {{option.value }}
                </mat-option>
            </mat-select>

        </mat-form-field>

    </div>


    <div class="pt-3 pb-1 pr-6">
        <ngx-shimmer-loading *ngIf="!this.companyActivityChart" [shape]="'square'" [borderRadius]="'0px'"
            [width]="'650px'"></ngx-shimmer-loading>
        <div id="activityChart"></div>
        <!-- <vex-chart #chart1 [options]="companyActivityOptions" [series]="companyActivityChart"></vex-chart> -->
    </div>
</div>
