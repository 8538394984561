<mat-dialog-content>

    <div matDialogTitle class="relative flex items-start justify-between">
        <img class="w-20 m-auto" [src]="this.data.imageUrl" alt="">
        <button mat-icon-button class="absolute right-0 close-button">
            <mat-icon mat-dialog-close="true" [icIcon]="icClose" height="22px" width="22px"></mat-icon>
        </button>
    </div>

    
    <!-- <div class="block w-40 mx-auto text-center">
        <img class="w-full" [src]="this.data.imageUrl" alt="">
    </div> -->
    <div class="mt-4">
        <h5 class="text-[18px] mb-2 font-semibold text-[#045cd9]  text-center">{{this.data.title}}</h5>
       
    </div>
</mat-dialog-content>