import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';
@Component({
  selector: 'vex-how-it-work-dialog',
  templateUrl: './how-it-work-dialog.component.html',
  styleUrls: [
    './how-it-work-dialog.component.scss',
    '../../../node_modules/quill/dist/quill.snow.css',
    '../../@vex/styles/partials/plugins/_quill.scss'
  ]
})
export class HowItWorkDialogComponent implements OnInit {
  icClose = icClose
  quilData =''
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        
      ]
    }
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private dialogRef: MatDialogRef<HowItWorkDialogComponent> 
  ) {
    console.log("data", this._data);
   }

  ngOnInit(): void {
     this.quilData =this._data?.content?.description;
    // let description = document.getElementById('howItWork');
    // description.innerHTML = this._data?.content?.description;
  }

}
