<div>
    <div>
        <div class="flex items-baseline w-full mb-5">
            <div>
                <h3 class="mt-2 text-center"> {{'You have an ongoing activity at the moment that ends'}}
                    {{this.lastChallengeDate | date}}
                    {{'Would you like to replace this activity, or create a new one that begins right after the current one completes?'}} </h3>
            </div>
            <div class="relative">
                <button class="custom-close-btn" (click)="close()">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                </button>
            </div>
        </div>
    </div>


    <mat-dialog-actions align="end" class="-mb-4">
        <button mat-raised-button color="primary" (click)="continue('continue')" mat-dialog-close tabindex="-1" class="w-28">{{"REPLACE"}}</button>
        <button mat-raised-button color="secondary" (click)="continue('cancel')" mat-dialog-close tabindex="-1"  class="w-32">{{"ADD IN QUEUE"}}</button>
    </mat-dialog-actions>
</div>