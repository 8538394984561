import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthServiceLocal } from '../services/auth.service';
import * as _ from 'lodash';    

@Component({
  selector: 'vex-edit-focus-dialog',
  templateUrl: './edit-focus-dialog.component.html',
  styleUrls: ['./edit-focus-dialog.component.scss']
})
export class EditFocusDialogComponent implements OnInit {

  step : number = 1;
  focusText:string = "What are the focus areas for your company?"
  personalizeText:string = "Let's personalize your actionable steps!"
 
  focusAreaforSelection: any[];
  focusSelectedCate: any;
  focusIsSelectionDisabled: boolean = true;
  focusSelectedCount: any = [];

  personalizeSelectedCount: any = [];
  selectedForPersonalize: any =[]

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
  private dialogRef: MatDialogRef<EditFocusDialogComponent>,
  private dialog: MatDialog,private snackbar : MatSnackBar,
  public auth : AuthServiceLocal
  ) { }

  ngOnInit(): void {
    this.getKpiAreaList()
  }


  focusOnCategorySelect(value){
    this.focusAreaforSelection.forEach((element) => {
      if (element.name == value.name) {
        if (element.isSelected) {
          element["isSelected"] = false;
        } else {
          if (this.focusSelectedCount?.length < 3) {
            element["isSelected"] = true;
          }
        }
      }
    });
  
    this.focusSelectedCount = this.focusAreaforSelection.filter(
      (element) => element.isSelected
    );
  

  }


  focusNext(){
    this.step =1
    

    this.selectedForPersonalize = _.cloneDeep(this.focusSelectedCount);

    this.selectedForPersonalize.map((el)=>(el.isSelected = false ,{...el})); 
    
    console.log(" this.focusSelectedCount", this.focusSelectedCount)
    this.saveFocusArea(this.focusSelectedCount)

  }

  saveFocusArea(value){

    let tempFocusedIds  = value.map(ele => {return ele.id} )

    console.log("tempFocusedIds",tempFocusedIds)
    const postData = {
      "focusKpiIds" : tempFocusedIds.toString()
    };

  console.log("postdata", postData)
  this.auth.updatekpisStatus(postData).subscribe((response: any) => {
    if(response.status == 200 ){

    }else{
      this.snackbar.open(response.message,"Okay",{duration: 2000})
    }
  })
  }


  cancel(value){
    console.log("value in cancel =====>",value)
    this.dialogRef.close()
  }


  personalizeOnCategorySelect(value){
    this.personalizeSelectedCount = [];
    this.selectedForPersonalize.forEach((element) => {
      if (element.name == value.name) {
        this.personalizeSelectedCount.push(element);
        element["isSelected"] = true;
      } else {
        this.personalizeSelectedCount.push("");
        element["isSelected"] = false;
      }
    });

  }


  getKpiAreaList(){
    this.auth.getKpiAreaList().subscribe((response: any) => {
      console.log("response data ", response)
      if(response.status == 200){

        response.data.kpis.forEach((element) => {
          element = element["isSelected"] = false;
        });
  
        this.focusAreaforSelection = response.data.kpis;

        console.log('this.focusAreaforSelection ==>',this.focusAreaforSelection);
        
  
        // let selectedStrategy: any = localStorage.getItem("kpiIds");
        let selectedStrategy: any = response.data.focusedKpiIds;
       
        // if (selectedStrategy != null || selectedStrategy == '') {
  
        //   console.log("focusAreaforSelection ===>",this.focusAreaforSelection)
        _.cloneDeep(this.focusAreaforSelection).forEach((ele) => {           
            _.cloneDeep(selectedStrategy).forEach((element) => {            
              if (element.id == ele.id) {
                ele.isSelected = true;
                console.log("selected data ===> ",ele)
                _.cloneDeep(this.focusSelectedCount).push(ele);
                
              }
            });
          });
        // }

       
       

        console.log("selectedStrategy ===> ",selectedStrategy)
        
        const matchedData = selectedStrategy.map(obj2 => {        
          const matchedObj = _.cloneDeep(this.focusAreaforSelection).find(obj1 => {
           
            if(obj1.id == obj2.id){
              if(response.data.selectedKpis[0].id == obj1.id){
                    obj1['isSelected'] = true
              }

              (this.selectedForPersonalize).push(obj1)
            }
          });
          
        });

        console.log("matchedData after imate data ==>",this.selectedForPersonalize)
        // this.focusAreaforSelection = response.data
      }else{
        this.snackbar.open(response.message, 'Ok', {duration:2000})
      }
    })
  }


  getSelectedFocus(){

  }

  back(){
    this.step = 2
    this.personalizeSelectedCount = [];
  }


  
  completeOnboard(){
    let tempKpiIds;
    this.personalizeSelectedCount.forEach((ele) => {
      if (ele.isSelected == true) {
        return (tempKpiIds = ele.id);
      }
    });

    //  console.log("temp value ===>",temp)
      const postData = {
        "kpiIds" : tempKpiIds
      };

    console.log("postdata", postData)
    this.auth.updatekpisStatus(postData).subscribe((response: any) => {
      if(response.status == 200){
        this.dialogRef.close(tempKpiIds.toString())
      }else{
        this.snackbar.open(response.message,"Okay",{duration:2000})
      }
    })
  }
}
