import {ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import usFlag from '@iconify/icons-emojione/flag-for-flag-united-states';
import norwayFlag from '@iconify/icons-emojione/flag-for-flag-norway';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import {language, Module} from '../../utils/systemEnums';
import {LanguageService} from '../../services/language.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStoreService } from 'src/@vex/services/local-store.service';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;
  navigationItems = this.navigationService.itemsArray;


  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  usFlag = usFlag;
  norwayFlag = norwayFlag;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  selectedIcon;
  language = language;
  selectedLang;
  breadCrumbsData;
  selectedModuleID: Number;
  Module = Module;
  Qparams :any ;
  isDesktop$ = this.layoutService.isDesktop$;
  // mobileQuery:string  ='1125px';

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService, private cd: ChangeDetectorRef,
              private popoverService: PopoverService, public langService: LanguageService,
              private store: LocalStoreService, private router: Router,private route: ActivatedRoute) {
    const isLangSet = this.langService.getLanguage();
    if (!isLangSet) {
      this.selectedIcon = this.usFlag;
    }
    this.getLanguage();


  }

  ngOnInit() {
    this.selectedModuleID = Number(this.store.getItem('selectedModuleID'));
    if(!this.selectedModuleID) {
      this.store.setItem('selectedModuleID', Module.Dashboard);
    }

    this.layoutService.selectedModuleBehavaour.subscribe(moduleID => {
      if(moduleID) {
        this.selectedModuleID = moduleID;
      }
    })
    
    this.navigationService.pageTitle$.subscribe(data => {
      this.breadCrumbsData = data;
    })
  }
  
  ngAfterViewInit(){  
    this.route.queryParams.subscribe(params => {
      this.Qparams = params;
    });
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  setLanguage(icon, lng) {
    this.selectedIcon = icon;
    this.langService.setLanguage(lng);
    this.navigationService.publishNavigationChange(lng);
    this.cd.detectChanges();
  }

  getLanguage() {
    // this.selectedLang = this.langService.getLanguage();
    if (this.langService.selectedLang === language.English) {
      this.selectedIcon = this.usFlag;
    } else if (this.langService.selectedLang === language.Norway) {
      this.selectedIcon = this.norwayFlag;
    }
  }
}
