<a *ngIf="isLink(item) && !isFunction(item.route)"
   [fragment]="item.fragment"
   (click)="onClickOnSidenavItem(item)"
   [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: true }"
   [routerLink]="item.route"
   class="item"
   fxLayout="row"
   matRipple
   routerLinkActive="active">
  <mat-icon *ngIf="level === 0 && item.icon" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
  <mat-icon *ngIf="level === 0 && item.svgIcon" [svgIcon]="item.svgIcon" class="item-icon" fxFlex="none"></mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
  <span *ngIf="item.badge &&  item.badge.value !== 0"
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
        class="item-badge"
        fxFlex="none">{{ item.badge.value }}</span>
</a>

<!--<a (click)="openNotificationModal($event)" *ngIf="isLink(item) && !isFunction(item.route) && item.route === '/send-notification'"-->
<!--   href="javascript:void(0)"-->
<!--   class="item text-capitalize"-->
<!--   fxLayout="row"-->
<!--   matRipple-->
<!--   routerLinkActive="active">-->
<!--  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>-->
<!--  <span class="item-label" fxFlex="auto">{{ item.label }}</span>-->
<!--  <span *ngIf="item.badge"-->
<!--        [ngClass]="[item.badge.bgClass, item.badge.textClass]"-->
<!--        class="item-badge"-->
<!--        fxFlex="none">{{ item.badge.value }}</span>-->
<!--</a>-->

<div (click)="item.route()"
     *ngIf="isLink(item) && isFunction(item.route)"
     class="item"
     fxLayout="row"
     matRipple
     routerLinkActive="active">
  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
  <span *ngIf="item.badge"
        [ngClass]="[item.badge.bgClass, item.badge.textClass]"
        class="item-badge"
        fxFlex="none">{{ item.badge.value }}</span>
</div>


<ng-container *ngIf="isDropdown(item)">
  <div (click)="toggleOpen()"
       [class.active]="isOpen || isActive"
       [class.open]="isOpen"
       class="item"
       fxLayout="row"
       matRipple>
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
    <span class="item-label" fxFlex="auto">{{ item.label }}</span>
    <span *ngIf="item.badge"
          [ngClass]="[item.badge.bgClass, item.badge.textClass]"
          class="item-badge"
          fxFlex="none">{{ item.badge.value }}</span>
    <mat-icon [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon" fxFlex="none"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>


<ng-container *ngIf="isSubheading(item)">
  <div class="subheading">{{ item.label }}</div>
  <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>


