<mat-dialog-content>
    <div class="w-full mb-5 text-center">
        <div class="mb-3">
            <img src="assets/img/demo/logo.svg" alt="" class="img-responsive" width="60" style="margin: 0 auto">
        </div>
        <h3 class="text-center"> {{'message.challengeCreateSuccess' | translate}} </h3>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button (click)="onConfirmClick({cancel: true})" color="primary" mat-dialog-close tabindex="-1">
        {{'buttons.cancel' | translate}}
    </button>
    <button mat-raised-button (click)="onConfirmClick({invite: true})" color="primary" mat-dialog-close tabindex="-1">
        {{'buttons.startInviting' | translate}}
    </button>
</mat-dialog-actions>
