import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-challenge-confirmation-dailog',
  templateUrl: './challenge-confirmation-dailog.component.html',
  styleUrls: ['./challenge-confirmation-dailog.component.scss']
})
export class ChallengeConfirmationDailogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
