import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthServiceLocal } from 'src/@vex/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PraiseColleagueService {
  teamOrMember: any = new BehaviorSubject([]);
  membersList: any;
  praiseColleagueModelData :any = new BehaviorSubject([]);              
  teamList: any;
  teamOrMemberArray: any =[];

  constructor(private auth: AuthServiceLocal,
    private snackbar: MatSnackBar) {
      this.getAllTeamOrColleagues()
  

  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
      return new Promise((resolve, reject) => {

        Promise.all([
          this.getAllTeamOrColleagues()
        ]).then(
            () => {
                resolve('');
            },
            reject
        );
    });
}

  getAllTeamOrColleagues() {
    const payload = {}
    this.auth.getAllMemberOrTeam(payload).subscribe((response: any) => {
      if (response.status === 200) {
      
        this.membersList =response.data.team
        this.teamList = response.data.member

        this.teamOrMemberArray = this.membersList.concat(this.teamList)
        console.log("latest array",this.teamOrMemberArray)

        const latestMembersList = this.teamOrMemberArray.map((element)=>({...element,checked:false}))

        console.log("memberlist",latestMembersList)
        this.teamOrMember.next(latestMembersList)
    
      } else {
        this.snackbar.open(response.message);
      }
    });
  }



}

