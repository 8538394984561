<div class="border-b py-4 px-6" fxLayout="row" fxLayoutAlign="start center">
    <h2 class="m-0 title new-card-title" fxFlex="auto">{{'Social Responsibility' | uppercase}}</h2>
    <span>
        <span class="font-weight-bold text-primary" *ngIf="this.currencySymbol?.currencyPlace == 1">{{ this.currencySymbol.currency}} </span>
        <span class="font-weight-bold text-primary"> {{totalDonation}} </span>
        <span class="font-weight-bold text-primary" *ngIf="this.currencySymbol?.currencyPlace == 2">{{ this.currencySymbol.currency}} </span>
        In reward
    </span>
    <mat-icon class="cursor-pointer" [icIcon]="icFilterList" (click)="openFilter('donation')"
        matTooltip="Filter" class="ml-3"></mat-icon>
    <img class="info ml-2 cursor-pointer" src="../../../assets/img/information_icon.png"
        (click)="openModal('Social Responsibility','Amount in dollars that have been donated to a charity, gift cards or other types of rewards to continue engaging and improving well-being.')">
</div>

<div class="filter-section" *ngIf="donationGoalCh" [class.opened]="donationGoalCh">
    <mat-form-field fxFlex="100">
        <mat-label> Select Year </mat-label>
        <mat-select [(value)]="isSelectedForDonation"
            (selectionChange)="changeActFilter($event.value,'donation')">
            <mat-option *ngFor="let option of  this.yearData | keyvalue" [value]="option.value">
                {{option.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="pt-3 pb-1 pr-6">
    <ngx-shimmer-loading *ngIf="!this.donationChart" [shape]="'square'" [borderRadius]="'0px'"
        [width]="'650px'"></ngx-shimmer-loading>
    <div id="donationChart"></div>
    <!-- <vex-chart #chart3 [options]="donationChartOptions" [series]="donationChartOptions.series">
        </vex-chart> -->
</div>