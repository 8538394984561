import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-common-info-dialog',
  templateUrl: './common-info-dialog.component.html',
  styleUrls: ['./common-info-dialog.component.scss']
})
export class CommonInfoDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public   data: any,
    private dialogRef: MatDialogRef<CommonInfoDialogComponent>
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.dialogRef.close()
    }, 2000);
  }

}
